
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue'), url(https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap) format('woff2');
}


.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}


.Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  background: black;
  margin: 1.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 2rem;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

ul.nav {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39a2db;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}

ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}

ul.nav li:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

ul.nav li:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}

ul.nav li.active {
  background:#e50914;
}

.TabContent {
  font-size: 2rem;
  text-align: center;
}

.totals {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: -0.04em;
  color: #e50914;
  text-transform: uppercase;
  text-shadow: 2px 2px 0 #000000;
  transform: skew(-15deg) rotate(-15deg);
  margin-left: -10px;
}

.home-background {
  background-image: url("https://cdn.mos.cms.futurecdn.net/rDJegQJaCyGaYysj2g5XWY.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.submit-button {
  font-family: "Netflix Sans", sans-serif;
  font-size: 0.3em;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  background-color: #e50914;
  border: none;
  padding: 1em 2em;
  margin: 1em;
  border-radius: 0.2em;
  box-shadow: 2px 2px 0 #000000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #b20710;
  box-shadow: 4px 4px 0 #000000;
}

.shimmer-wrapper {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f0f0f0 25%,
    #e0e0e0 50%,
    #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.MuiGrid-item {
  padding-left: 40px !important;
}


@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
