.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.24);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.navigation-menu li a:hover {
  background-color: #e50914;
  color: #fff;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #e50914;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #828fc4;
}
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  
  .navigation-menu li a:hover {
    background-color: #e50914;
    color: #fff;
  }
  

  ul li a {
    font-family: "Arial", sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  
  ul li a:hover {
    background-color: #e50914;
    color: #fff;
  }
  
}
@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .navigation-menu.expanded ul {
    display: block;
  }
}